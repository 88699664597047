import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

export interface SnackState {
  open: boolean
  isSuccess: boolean
  message: string
}

export const initialSnackState: SnackState = {
  open: false,
  isSuccess: false,
  message: '',
}

export const Alert = (props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
)

interface Props {
  open: boolean
  isSuccess: boolean
  message: string
  close(): void
  disableAutoHide?: boolean
}

const Snack: React.FC<Props> = (props: Props) => {
  const { isSuccess, message, open, close, disableAutoHide } = props

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    close()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={disableAutoHide ? null : 6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={isSuccess ? 'success' : 'error'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Snack
