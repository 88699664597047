import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Layout from '../../templates/LiveLayout'
import * as S from '../../styles/login-live.styles'
import * as I from '../../components/shared/Icons'
import { navigate, PageRendererProps } from 'gatsby'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import * as Colors from '../../constants/colors'
import * as session from '../../util/session'
import { BtnSelect } from '../../components/forms/util/BtnSelect'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Snack, { initialSnackState, SnackState } from '../../components/Snackbar'
import ImageCropper from '../../components/live/imageCropper'
import { withErrorHandler } from '../../components/errorHandler'
import { GenericWorkout, TrainerResponse } from '../../types/generic-workout'
import { api, apiWithFormData, Methods } from '../../services/httpService'
import { WorkoutState } from '../../components/UploadWorkout/UploadWorkoutDetails'
import { TRAINER_RECORDINGS } from '../../constants/routes'
import { PRIMARY } from '../../constants/colors'

export const Spacer = styled.div`
  margin-top: 20px;
`

export const TrainerImage = styled.img`
  width: 40px;
  height 40px;
  margin: 0;
  margin-right: 15px;
  border-radius: 50%;
`

export const TrainerRow = styled.div`
  display: flex;
  align-items: center;
`

export interface WorkoutField {
  label: string
  field: string
  type: string
  values?: string[]
}

export interface Config {
  title?: WorkoutField
  description?: WorkoutField
  descriptionTwo?: WorkoutField
  tags?: WorkoutField
  length?: WorkoutField
  thumbnail?: WorkoutField
  videoUrl?: WorkoutField
  spotifyLink?: WorkoutField
  intensity?: WorkoutField
}

interface EditWorkoutRequest {
  title?: string
  description?: string
  descriptionTwo?: string
  spotifyLink?: string
  type?: string
  intensity?: string
  tags?: string[]
  thumbnail?: string
  trainerId: number
  length?: number
}

const formatEditState = (workout: GenericWorkout) => ({
  thumbnailFile: null,
  title: workout.title,
  description: workout.description,
  descriptionTwo: workout.descriptionTwo,
  spotifyLink: workout.spotifyLink || '',
  length: workout.length,
  tags: workout.tags,
  intensity: workout.intensity,
  videoUrl: workout.video[0].videoURL,
})

const EditWorkoutVideo = (props: PageRendererProps): React.ReactElement => {
  console.log(props)
  const editWorkoutData: GenericWorkout =
    props.location.state && (props.location.state as any).editWorkoutData
  const queryClient = useQueryClient()
  const [workoutState, setWorkoutState] = useState<WorkoutState>(
    editWorkoutData ? formatEditState(editWorkoutData) : undefined,
  )
  const [imagePreview, setImagePreview] = useState(editWorkoutData?.thumbnail)
  const [selectedTrainer, setSelectedTrainer] = useState<TrainerResponse>(
    editWorkoutData?.trainer,
  )
  const [{ open, isSuccess, message }, setSnackState] = useState<SnackState>(
    initialSnackState,
  )

  const { isLoading: isLoadingWorkoutConfig, data: workoutConfig } = useQuery(
    'config:workout',
    async () => await api<WorkoutField[]>(Methods.get, '/config/workout'),
    {
      select: data => {
        const config: Config = {}
        data.map(item => {
          config[item.field] = item
        })
        return config
      },
    },
  )
  const { isLoading: isLoadingTrainers, data: trainers } = useQuery(
    'trainers',
    async () => await api<TrainerResponse[]>(Methods.get, '/trainer'),
  )
  const imageUpload = useMutation(async (file: File) => {
    const formData = new FormData()
    formData.append('image', file)
    return await apiWithFormData<{ imageURL: string }>(
      '/upload/image',
      formData,
    )
  })
  const editWorkout = useMutation(
    async (request: EditWorkoutRequest) =>
      await api(Methods.patch, `/workout/${editWorkoutData?.id}`, request),
    {
      onError: (error: any) => {
        setSnackState({
          open: true,
          isSuccess: false,
          message:
            error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        })
      },
      onSuccess: () => {
        setSnackState({
          open: true,
          isSuccess: true,
          message: 'Congratulations, your workout has been saved!',
        })
        queryClient.invalidateQueries('workouts')
      },
    },
  )

  if (!session.isTrainer()) {
    return <>Only trainers can access this url</>
  }

  const handleUploadClickThumbnail = (file: File) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setImagePreview(reader.result)
    }

    setWorkoutState({
      ...workoutState,
      thumbnailFile: file,
    })
  }

  const handleEditWorkout = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault()

    const {
      title,
      description,
      length,
      tags,
      descriptionTwo,
      spotifyLink,
      intensity,
      thumbnailFile,
      videoUrl,
    } = workoutState

    const thumbnailOptions: any = {}
    if (thumbnailFile) {
      const data = await imageUpload.mutateAsync(thumbnailFile)
      thumbnailOptions.thumbnail = data.imageURL
    }

    // edit workout
    const editWorkoutRequest: EditWorkoutRequest = {
      title,
      description,
      tags,
      length,
      intensity: intensity || undefined,
      videoUrl,
      ...thumbnailOptions,
      // intentionally passing trainer's `trainer` id rather then `user` id
      trainerId: selectedTrainer.id,
      ...{
        spotifyLink: workoutConfig.spotifyLink ? spotifyLink : undefined,
      },
      ...{
        descriptionTwo: workoutConfig.descriptionTwo
          ? descriptionTwo
          : undefined,
      },
    }

    await editWorkout.mutateAsync(editWorkoutRequest)
  }

  // const isTest = title.includes('TestCypressTitle')

  // const submitWorkout = (data: EditWorkoutRequest, token: string) => {
  //   // if (thumbnailFile === null && imagePreview !== '') {
  //   //   data.thumbnail = imagePreview
  //   // }

  //   // if (isTest) {
  //   //   data.thumbnail =
  //   //     'https://storage.googleapis.com/vod-uploads/1612721916527_workoutThumbnail'
  //   // }

  //   const axiosRequest = axios.patch(
  //     `${API_ROOT}/workout/${editWorkoutData?.id}`,
  //     data,
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //         'x-client-name': X_CLIENT_NAME,
  //       },
  //     },
  //   )

  //   axiosRequest
  //     .then(() => {
  //       setSnackState({
  //         open: true,
  //         isSuccess: true,
  //         message: 'Congratulations, your workout has been saved!',
  //       })
  //     })
  //     .catch(err => {
  //       setSnackState({
  //         open: true,
  //         isSuccess: false,
  //         message:
  //           err?.message ||
  //           "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
  //       })
  //     })
  // }

  const handleTrainerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const trainer = trainers.find(val => val.id === Number(e.target.value))
    setSelectedTrainer(trainer)
  }

  return (
    <Layout title={'Editing Workout'} location={props.location}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => navigate(TRAINER_RECORDINGS)}
      >
        <I.ChevronLeft lg color={PRIMARY} />
        <div style={{ marginLeft: 12 }}>Back</div>
      </div>
      <S.Container>
        {isLoadingWorkoutConfig || isLoadingTrainers || !!!workoutState ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleEditWorkout}>
            {workoutConfig ? (
              <>
                <S.WrapCol>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Trainer
                    </InputLabel>
                    <Select
                      labelId="outlined-label-trainer"
                      id="workoutTrainer"
                      value={selectedTrainer?.id || ''}
                      // @ts-ignore
                      onChange={e => handleTrainerSelect(e)}
                      label="Trainer"
                      required={true}
                    >
                      {trainers.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.id}
                          id={`workoutTrainerItem${index}`}
                        >
                          <TrainerRow>
                            <TrainerImage src={item.profilePictureURL} />
                            {item.firstName} {item.lastName}
                          </TrainerRow>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Spacer />

                  {workoutConfig.title && (
                    <>
                      <TextField
                        label="Title"
                        type="text"
                        id="workoutTitle"
                        value={workoutState.title}
                        onChange={e =>
                          setWorkoutState({
                            ...workoutState,
                            title: e.target.value,
                          })
                        }
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                      />
                      <Spacer />
                    </>
                  )}

                  {workoutConfig.description && (
                    <>
                      <TextField
                        value={workoutState.description}
                        label="Description"
                        id="workoutDesc"
                        placeholder="Tell consumers a little more about your class and list any equipment needed"
                        onChange={e =>
                          setWorkoutState({
                            ...workoutState,
                            description: e.target.value,
                          })
                        }
                        multiline={true}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                      />
                      <Spacer />
                    </>
                  )}

                  {workoutConfig.descriptionTwo && (
                    <>
                      <TextField
                        value={workoutState.descriptionTwo}
                        label="Description Two (optional)"
                        id="workoutDescTwo"
                        placeholder="Tell consumers a little more about your class and list any equipment needed"
                        onChange={e =>
                          setWorkoutState({
                            ...workoutState,
                            descriptionTwo: e.target.value,
                          })
                        }
                        multiline={true}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                      />
                      <Spacer />
                    </>
                  )}

                  {workoutConfig.spotifyLink && (
                    <>
                      <TextField
                        value={workoutState.spotifyLink}
                        label="Music Playlist Link"
                        id="workoutSpotify"
                        onChange={e =>
                          setWorkoutState({
                            ...workoutState,
                            spotifyLink: e.target.value,
                          })
                        }
                        multiline={true}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                      />
                      <Spacer />
                    </>
                  )}

                  <>
                    <TextField
                      value={workoutState.videoUrl}
                      label="Video Url"
                      id="workoutVideoUrl"
                      onChange={e =>
                        setWorkoutState({
                          ...workoutState,
                          videoUrl: e.target.value,
                        })
                      }
                      variant="outlined"
                      style={{ width: '100%' }}
                      required
                    />
                    <Spacer />
                  </>

                  {workoutConfig.length && (
                    <>
                      <Spacer />
                      <Typography
                        id="discrete-slider"
                        gutterBottom
                        style={{
                          color: Colors.themeColors.textColor,
                          fontSize: 16,
                        }}
                      >
                        Duration (in minutes)
                      </Typography>
                      <Slider
                        value={workoutState.length}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={1}
                        max={120}
                        onChange={(e, value) =>
                          setWorkoutState({
                            ...workoutState,
                            length: value as number,
                          })
                        }
                      />
                    </>
                  )}

                  {workoutConfig.tags && (
                    <>
                      <Spacer />
                      <BtnSelect
                        options={workoutConfig.tags.values.map(item => ({
                          key: item,
                          value: item,
                        }))}
                        values={workoutState.tags || []}
                        testId="workoutType"
                        label="Type (select all that apply)"
                        onClick={(values): void =>
                          setWorkoutState({
                            ...workoutState,
                            tags: values,
                          })
                        }
                      />
                    </>
                  )}

                  {workoutConfig.intensity && (
                    <>
                      <Spacer />
                      <BtnSelect
                        options={workoutConfig.intensity.values.map(item => ({
                          key: item,
                          value: item,
                        }))}
                        values={[workoutState.intensity]}
                        label="Intensity"
                        testId="workoutIntensity"
                        isSingleSelect={true}
                        onClick={(values): void =>
                          setWorkoutState({
                            ...workoutState,
                            intensity: values.shift(),
                          })
                        }
                      />
                    </>
                  )}

                  {workoutConfig.thumbnail && (
                    <>
                      {imagePreview && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <S.ImagePreview src={imagePreview} />
                          <div style={{ paddingRight: 20 }} />
                          <IconButton
                            color="primary"
                            aria-label="remove video"
                            onClick={() => {
                              setWorkoutState({
                                ...workoutState,
                                thumbnailFile: null,
                              })
                              setImagePreview(null)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      )}
                      <ImageCropper onSave={handleUploadClickThumbnail} />
                      <div style={{ marginBottom: 40 }} />
                    </>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    id="uploadSubmit"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Spacer />
                </S.WrapCol>
                <Snack
                  open={open}
                  isSuccess={isSuccess}
                  disableAutoHide={true}
                  message={message}
                  close={() => {
                    setSnackState(initialSnackState)
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  paddingTop: '10%',
                }}
              >
                <CircularProgress />
              </div>
            )}
          </form>
        )}
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(EditWorkoutVideo)
